<template>
  <!-- eslint-disable -->
  <v-dialog v-model="dialog" scrollable persistent max-width="800px" content-class="auth-dialog-wrapper">
    <template #default>
      <div class="auth-dialog">
        <div class="auth-dialog-header">
          <!-- header-logo -->
          <div class="header-logo-container">
            <a href="/">
              <img src="site_images/logo_honely.png" class="header-logo" alt="Honely" />
            </a>
            <button class="btn-close" @click="hideDialog()">
              <i class="fa fa-times" style="color: black;" />
            </button>
          </div>
          <!-- header-logo -->
        </div>
        <div class="auth-dialog-content" style="height: 50vh;">
          <p class="search-text-helper">Enter a property to purchase</p>
          <honely-search :listings="false" :standard="false" :searchButton="true" :custom="true"
            @customClick="customClick" />
        </div>
      </div>
    </template>
  </v-dialog>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
export default {
  components: {
    HonelySearch: () => import('@/components/HonelySearch'),
  },

  props: {
    show: Boolean,
  },

  computed: {
    dialog: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('toggleShow', value)
      },
    },
  },

  methods: {
    hideDialog() {
      this.dialog = false
    },
    customClick(value) {
      this.$emit("customClick", value)
    }
  },
}
</script>

<style scoped>

</style>
